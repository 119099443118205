import TextFieldExtension from "../../@libs/TextFieldExtension";

import dateValidation from "../../validations/date";
import required from "@libs/required";

class DateTextField extends TextFieldExtension {
	constructor(...args) {
		super(...args);
	}

	get val() {
		return super.val;
	}

	get id() {
		return super.id;
	}

	get name() {
		return super.name;
	}

	get isValid() {
		return super.isValid;
	}

	get rawValue() {
		return this.val.replace(/\D/g, "");
	}

	validate(val = required`val`, customValidation) {
		return super.validate(
			val,
			customValidation || super.validation || dateValidation
		);
	}

	displayError(val = required`val`) {
		super.displayError(val);
	}
}

export default DateTextField;
