export default (theme) => ({
	root: {
		"&.Mui-error": {
			color: theme.palette.error.main,
		},
	},
	adornedStart: {
		"&.Mui-error": {
			color: theme.palette.error.main,
		},
	},
	adornedEnd: {
		"&.Mui-error": {
			color: theme.palette.error.main,
		},
	},
});
