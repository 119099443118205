import { action, observable } from "mobx";
import required from "@libs/required";

class TextFieldExtension {
	@observable showError = false;

	ref;
	groupId;
	isRequired;
	validation;
	hasError = false;
	errorMessage = "";
	validationContext = { context: {} };

	constructor(rootStore, ref, groupId, validation) {
		this.rootStore = rootStore;
		this.ref = ref;
		this.groupId = groupId;
		this.validation = validation;
	}

	get val() {
		if (!this.ref.current) return;
		return this.ref.current.value;
	}

	get id() {
		if (!this.ref.current) return;
		return this.ref.current.id;
	}

	get name() {
		if (!this.ref.current) return;
		return this.ref.current.name;
	}

	isValid(validation) {
		if (this.validate(this.val, this.validation || validation) === false) {
			this.displayError(true);
			return false;
		}

		this.displayError(false);
		return true;
	}

	validate(val = required`val`, validation = required`validation`) {
		try {
			validation.validateSync(val, this.validationContext);
			this.hasError = false;
		} catch (err) {
			this.errorMessage = err.message;
			this.hasError = true;
		}
		return !this.hasError;
	}

	@action
	displayError(val = required`val`) {
		this.showError = val;
	}
}

export default TextFieldExtension;
