import TextFieldExtension from "../../@libs/TextFieldExtension";

import ccCodeValidation from "../../validations/creditcard-code";

import required from "@libs/required";
import { action, computed, observable, toJS } from "mobx";

import ccValidator from "card-validator";

class CreditCardCodeTextField extends TextFieldExtension {
	@observable currentCodeName;

	constructor(...args) {
		super(...args);
	}

	@observable _mask = [/\d/];
	@computed get mask() {
		return toJS(this._mask);
	}

	get val() {
		return super.val;
	}

	get id() {
		return super.id;
	}

	get name() {
		return super.name;
	}

	get isValid() {
		return super.isValid;
	}

	get rawValue() {
		const { card } = ccValidator.number(this.val);
		if (!card) {
			return {};
		}
		return card.code;
	}

	@action
	calculateMask(ccNumber = "") {
		ccNumber = ccNumber.replace(/\D/g, "");
		this._mask = [];

		const { card } = ccValidator.number(ccNumber);
		if (!card) {
			const size = 4;
			[...Array(size)].forEach(() => this._mask.push(/\d/));
			Object.assign(this.validationContext.context, { size });
			return;
		}

		[...Array(card.code.size)].forEach(() => this._mask.push(/\d/));
		Object.assign(this.validationContext.context, card.code);
	}

	validate(val = required`val`, customValidation) {
		return super.validate(
			val,
			customValidation || super.validation || ccCodeValidation
		);
	}

	displayError(val = required`val`) {
		super.displayError(val);
	}
}

export default CreditCardCodeTextField;
