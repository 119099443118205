import InstanceFactoryStoreManager from "@src/stores/InstanceFactoryStoreManager";
import required from "@libs/required";

import ZipcodeTextField from "./types/ZipcodeTextField";
import EmailTextField from "./types/EmailTextField";
import PasswordTextField from "./types/PasswordTextField";
import PhoneTextField from "./types/PhoneTextField";
import BasicTextField from "./types/BasicTextField";
import CreditCardTextField from "./types/CreditCardTextField";
import CreditCardCodeTextField from "./types/CreditCardCodeTextField";
import CreditCardExpTextField from "./types/CreditCardExpTextField";
import NameTextField from "./types/NameTextField";
import MoneyTextField from "./types/MoneyTextField";
import DateTextField from "./types/DateTextField";

class TextFieldStore extends InstanceFactoryStoreManager {
	constructor(rootStore) {
		super(rootStore);
	}

	__groupInstancesBy(field = required`field`, instances) {
		return (instances || this.instances).reduce((result, item) => {
			const key = item.instance[field];
			if (!result[key]) result[key] = [];
			result[key].push(item);
			return result;
		}, {});
	}

	__checkInstancesValidity(instances = [], validation, strict = false) {
		let fails = 0;
		for (const item of instances) {
			if (item.instance.isRequired && !item.instance.isValid(validation))
				fails++;
		}

		if (strict) return fails === 0;
		const requiredInstances = instances.filter(
			(item) => item.instance.isRequired
		);
		if (requiredInstances.length === 0 && fails === 0) return true;
		return fails < requiredInstances.length;
	}

	__checkInstances(instances = {}, validation) {
		let valid = true;

		for (const key in instances) {
			if (instances.hasOwnProperty(key) === false) continue;
			if (valid === false) {
				this.__checkInstancesValidity(instances[key], validation, !key);
				continue;
			}
			valid = this.__checkInstancesValidity(instances[key], validation, !key);
		}

		return valid;
	}

	__getInstanceByType(type) {
		if (type === "zipcode") return ZipcodeTextField;
		if (type === "email") return EmailTextField;
		if (type === "tel") return PhoneTextField;
		if (type === "password") return PasswordTextField;
		if (type === "text") return BasicTextField;
		if (type === "number") return BasicTextField;
		if (type === "cc") return CreditCardTextField;
		if (type === "cc-code") return CreditCardCodeTextField;
		if (type === "cc-exp") return CreditCardExpTextField;
		if (type === "name") return NameTextField;
		if (type === "money") return MoneyTextField;
		if (type === "date") return DateTextField;
	}

	init({
		type = required`type`,
		groupId,
		id = required`id`,
		ref = required`ref`,
		validation,
	}) {
		const Instance = this.__getInstanceByType(type);
		super.init(id, function (rootStore) {
			return new Instance(rootStore, ref, groupId, validation);
		});
	}

	findInstanceById(id = required`id`) {
		return super.findInstanceById(id);
	}

	reset() {
		super.reset();
	}

	removeInstanceById(id = required`id`) {
		return super.removeInstanceById(id);
	}

	isValid(id, validation) {
		const instancesGroupedByName = this.__groupInstancesBy("name");
		if (!id) return this.__checkInstances(instancesGroupedByName, validation);

		const instance = this.findInstanceById(id);
		if (!instance) return false;

		const instances = instance.name
			? instancesGroupedByName[instance.name]
			: [{ instance }];
		return this.__checkInstancesValidity(instances, validation, !instance.name);
	}

	isValidGroup(groupId = required`groupId`, validation) {
		let instances = this.__groupInstancesBy("groupId");
		if (!instances[groupId]) return false;

		instances = this.__groupInstancesBy("name", instances[groupId]);
		return this.__checkInstances(instances, validation);
	}

	showError(id = required`id`) {
		const instance = this.findInstanceById(id);
		if (!instance) return;
		return instance.showError;
	}

	errorMessage(id = required`id`) {
		const instance = this.findInstanceById(id);
		if (!instance) return;
		return instance.errorMessage;
	}

	displayError(id = required`id`, val = required`val`) {
		const instance = this.findInstanceById(id);
		if (!instance) return;

		let instancesGroupedByName;

		if (instance.groupId) {
			const instancesGroupedByGroupId = this.__groupInstancesBy("groupId");
			instancesGroupedByName = this.__groupInstancesBy(
				"name",
				instancesGroupedByGroupId[instance.groupId]
			);
		} else instancesGroupedByName = this.__groupInstancesBy("name");

		if (!instance.name) return instance.displayError(val);

		const instances = instancesGroupedByName[instance.name];
		if (!instances || instances.length === 0) {
			instance.displayError(val);
			return;
		}

		for (const item of instances) {
			item.instance.displayError(val);
		}
	}
}

export default TextFieldStore;
