import TextFieldExtension from "../../@libs/TextFieldExtension";

import passwordValidation from "../../validations/password";
import required from "@libs/required";

class PasswordTextField extends TextFieldExtension {
	constructor(...args) {
		super(...args);
	}

	get val() {
		return super.val;
	}

	get id() {
		return super.id;
	}

	get name() {
		return super.name;
	}

	get isValid() {
		return super.isValid;
	}

	validate(val = required`val`, customValidation) {
		return super.validate(
			val,
			customValidation || super.validation || passwordValidation
		);
	}

	displayError(val = required`val`) {
		super.displayError(val);
	}
}

export default PasswordTextField;
