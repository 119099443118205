import { observable, action, computed, toJS } from "mobx";
import TextFieldExtension from "../../@libs/TextFieldExtension";

import moneyValidation from "../../validations/money";
import required from "@libs/required";

class MoneyTextField extends TextFieldExtension {
	constructor(...args) {
		super(...args);
	}

	@observable _mask = [/\d/];
	@computed get mask() {
		return toJS(this._mask);
	}

	@action
	calculateMask(val = required`val`) {
		val = val.replace(/\D/g, "");
		const mask = [/\d/];
		for (let i = val.length; i >= 0; i--) {
			const count = val.length - i;
			if (count === 0) {
				mask.unshift(/\d/);
				continue;
			}
			if (count - 1 !== 0 && (count - 1) % 3 === 0) {
				mask.unshift(",");
				mask.unshift(/\d/);
				continue;
			}
			mask.unshift(/\d/);
		}
		this._mask = mask;
	}

	get val() {
		return super.val;
	}

	get id() {
		return super.id;
	}

	get name() {
		return super.name;
	}

	get isValid() {
		return super.isValid;
	}

	validate(val = required`val`, customValidation) {
		return super.validate(
			val,
			customValidation || super.validation || moneyValidation
		);
	}

	displayError(val = required`val`) {
		super.displayError(val);
	}
}

export default MoneyTextField;
