import TextFieldExtension from "../../@libs/TextFieldExtension";

import ccValidation from "../../validations/creditcard";
import required from "@libs/required";

import ccValidator from "card-validator";
import { action, computed, observable, toJS } from "mobx";

class CreditCardTextField extends TextFieldExtension {
	delimiter;

	constructor(...args) {
		super(...args);
	}

	@observable _mask = [/\d/];
	@computed get mask() {
		return toJS(this._mask);
	}

	get val() {
		return super.val;
	}

	get id() {
		return super.id;
	}

	get name() {
		return super.name;
	}

	get isValid() {
		return super.isValid;
	}

	get rawValue() {
		const val = this.val.replace(/\D/g, "");
		const { card } = ccValidator.number(val);
		return Object.assign({ value: val }, card || {});
	}

	@action
	calculateMask(ccNumber = "") {
		ccNumber = ccNumber.replace(/\D/g, "");

		this._mask = [...[...Array(ccNumber.length)].map(() => /\d/), /\d/];
		const { card } = ccValidator.number(ccNumber);
		if (!card) {
			return;
		}

		const length =
			card.lengths.find((length) => length > ccNumber.length) ||
			card.lengths[card.lengths.length - 1];
		const gapEnd = card.gaps[card.gaps.length - 1];

		this._mask = card.gaps.reduce((result, num, index) => {
			const spread = num - (index > 0 ? card.gaps[index - 1] : 0);
			[...Array(spread)].forEach(() => result.push(/\d/));
			result.push(this.delimiter);
			return result;
		}, []);

		[...Array(length - gapEnd)].forEach(() => this._mask.push(/\d/));
	}

	validate(val = required`val`, customValidation) {
		return super.validate(
			val.replace(/\D/g, ""),
			customValidation || super.validation || ccValidation
		);
	}

	displayError(val = required`val`) {
		super.displayError(val);
	}
}

export default CreditCardTextField;
